<template>
    <div class="transfer">
        <h5header :name="$t('manage.desc87')" :right="false" />
        <div class="transfer-con">
            <div class="transfer-tbg flex">
                <div @click="$router.go(-1)">{{ $t('new.desc3') }}</div>
                <div class="flex"><img src="../../public/images/new/righticon2.png" alt="" /> {{ $t('manage.desc87') }}
                </div>
            </div>
            <div class="tabnav flex">
                <div :class="{ tabact: act == 1 }" @click="handlerTab(1)">{{ $t('manage.desc88') }}</div>
                <div :class="{ tabact: act == 2 }" @click="handlerTab(2)">{{ $t('manage.desc89') }}</div>
            </div>

            <div class="transfer-time flex">
                <el-date-picker @change="dateSelect" v-model="value1" type="datetimerange"
                    :range-separator="$t('record.desc2')" :start-placeholder="$t('record.desc1')"
                    :end-placeholder="$t('record.desc3')">
                </el-date-picker>
                <el-select v-model="opType" :placeholder="$t('manage.desc105')" v-if="act == 1" @change="onChange">
                    <el-option :value="''" :label="$t('record.desc14')"></el-option>
                    <el-option :value="1" :label="$t('manage.desc106')"></el-option>
                    <el-option :value="2" :label="$t('manage.desc107')"></el-option>
                </el-select>
             
            </div>

            <div class="rr-h5-time">
                <img src="../../public/images/asset/time-icon.png" />
                <div class="item" @click="startShow = true">
                    <!-- <input type="text" v-model="startTime" :placeholder="$t('record.desc1')" disabled /> -->
                    <div v-if="startTime == ''">{{ $t('record.desc1') }}</div>
                    <p v-else>{{ startTime1 }}</p>
                </div>
                <span>{{ $t("record.desc2") }}</span>
                <div class="item" @click="endShow = true">
                    <!-- <input type="text" v-model="endTime" :placeholder="$t('record.desc3')" disabled /> -->
                    <div v-if="endTime == ''">{{ $t('record.desc3') }}</div>
                    <p v-else>{{ endTime1 }}</p>
                </div>
            </div>
            <div class="rr-h5-time">
                <el-select v-model="opType" :placeholder="$t('manage.desc105')" v-if="act == 1" @change="onChange">
                    <el-option :value="''" :label="$t('record.desc14')"></el-option>
                    <el-option :value="1" :label="$t('manage.desc106')"></el-option>
                    <el-option :value="2" :label="$t('manage.desc107')"></el-option>
                </el-select>
                <el-select v-model="opType" :placeholder="$t('manage.desc105')" v-else @change="onChange">
                    <el-option :value="''" :label="$t('record.desc14')"></el-option>
                    <el-option :value="1" :label="$t('manage.desc108')"></el-option>
                    <el-option :value="2" :label="$t('manage.desc109')"></el-option>
                </el-select>
            </div>
            <div class="transfer-table" v-if="act == 1">
                <div class="table-thead flex">
                    <div>{{ $t('manage.desc90') }}</div>
                    <div>{{ $t('manage.desc91') }}</div>
                    <div>{{ $t('manage.desc92') }}</div>
                    <div>{{ $t('manage.desc93') }}</div>
                    <div>{{ $t('manage.desc94') }}</div>
                    <div>{{ $t('manage.desc95') }}</div>
                    <div>{{ $t('manage.desc96') }}</div>
                    <div>{{ $t('manage.desc97') }}</div>
                    <div>{{ $t('manage.desc98') }}</div>
                    <div>{{ $t('manage.desc99') }}</div>
                    <div>{{ $t('manage.desc105') }}</div>
                    <div>{{ $t('manage.desc100') }}</div>
                </div>
                <div class="table-tbody" v-loading="loading" :element-loading-text="$t('common.desc7')">
                    <div class="tbody-list flex" v-for="item in list" :key="item.id">
                        <div>{{ item.createTime }}</div>
                        <div>{{ $t('manage.desc102') }}</div>
                        <div>{{ item.planteName }}</div>
                        <div>L{{ item.level }} {{ item.planetType }}</div>
                        <div>{{ item.track }}-{{ item.no }}</div>
                        <div>{{ item.sellLandCount }}/{{ item.totalLandCount }}</div>
                        <div>{{ item.planetPrice }} {{ item.planetCoinName }}</div>
                        <div>{{ item.amount }} {{ item.amountCoinName }}</div>
                        <div>{{ item.transOutName }}</div>
                        <div>{{ item.recommendUserName || '--' }}</div>
                        <div><span v-if="item.opType == 1">{{ $t('manage.desc106') }}</span><span v-else>{{
                            $t('manage.desc107') }}</span></div>
                        <div>{{ $t('assetbag.desc85') }}</div>
                    </div>
                    <div class="no-data" v-if="showNull">
                        <img src="../../public/images/no-data.png" alt="" />
                        <span>{{ $t("record.desc13") }}</span>
                    </div>
                </div>
            </div>
            <div class="transfer-table" v-else>
                <div class="table-thead flex">
                    <div>{{ $t('manage.desc90') }}</div>
                    <div>{{ $t('manage.desc91') }}</div>
                    <div>{{ $t('manage.desc92') }}</div>
                    <div>{{ $t('manage.desc93') }}</div>
                    <div>{{ $t('manage.desc94') }}</div>
                    <div>{{ $t('manage.desc95') }}</div>
                    <div>{{ $t('manage.desc96') }}</div>
                    <div>{{ $t('manage.desc104') }}</div>
                    <div>{{ $t('manage.desc99') }}</div>
                    <div>{{ $t('manage.desc100') }}</div>
                </div>
                <div class="table-tbody" v-loading="loading" :element-loading-text="$t('common.desc7')">
                    <div class="tbody-list flex" v-for="item in list" :key="item.id">
                        <div>{{ item.createTime }}</div>
                        <div>{{ $t('manage.desc103') }}</div>
                        <div>{{ item.planteName }}</div>
                        <div>L{{ item.level }} {{ item.planetType }}</div>
                        <div>{{ item.track }}-{{ item.no }}</div>
                        <div>{{ item.sellLandCount }}/{{ item.totalLandCount }}</div>
                        <div>{{ item.planetPrice }} {{ item.planetCoinName }}</div>
                        <div>{{ item.transInName }}</div>
                        <div>{{ item.recommendUserName || '--' }}</div>
                       
                        <div>{{ $t('assetbag.desc85') }}</div>
                    </div>
                    <div class="no-data" v-if="showNull">
                        <img src="../../public/images/no-data.png" alt="" />
                        <span>{{ $t("record.desc13") }}</span>
                    </div>
                </div>
            </div>
            <div class="h5table-list">
                <div class="list-con" v-for="item in list" :key="item.id">
                    <div class="list-name flex">
                        <div>{{ $t('manage.desc90') }}</div>
                        <p>{{ item.createTime }}</p>
                    </div>
                    <div class="list-name flex">
                        <div>{{ $t('manage.desc91') }}</div>
                        <p v-if="act == 1">{{ $t('manage.desc102') }}</p>
                        <p v-else>{{ $t('manage.desc103') }}</p>
                    </div>
                    <div class="list-name flex">
                        <div>{{ $t('manage.desc92') }}</div>
                        <p>{{ item.planteName }}</p>
                    </div>
                    <div class="list-name flex">
                        <div>{{ $t('manage.desc93') }}</div>
                        <p>L{{ item.level }} {{ item.planetType }}</p>
                    </div>
                    <div class="list-name flex">
                        <div>{{ $t('manage.desc94') }}</div>
                        <p>{{ item.track }}-{{ item.no }}</p>
                    </div>
                    <div class="list-name flex">
                        <div>{{ $t('manage.desc95') }}</div>
                        <p>{{ item.sellLandCount }}/{{ item.totalLandCount }}</p>
                    </div>
                    <div class="list-name flex">
                        <div>{{ $t('manage.desc96') }}</div>
                        <p>{{ item.planetPrice }} {{ item.planetCoinName }}</p>
                    </div>
                    <div class="list-name flex" v-if="act == 1">
                        <div>{{ $t('manage.desc97') }}</div>
                        <p>{{ item.amount }} {{ item.amountCoinName }}</p>
                    </div>
                    <div class="list-name flex" v-if="act == 1">
                        <div>{{ $t('manage.desc98') }}</div>
                        <p>{{ item.transOutName }}</p>
                    </div>
                    <div class="list-name flex" v-if="act == 2">
                        <div>{{ $t('manage.desc104') }}</div>
                        <p>{{ item.transInName }}</p>
                    </div>
                    <div class="list-name flex">
                        <div>{{ $t('manage.desc99') }}</div>
                        <p>{{ item.recommendUserName || '--' }}</p>
                    </div>
                    <div class="list-name flex" v-if="act == 1">
                        <div>{{ $t('manage.desc105') }}</div>
                        <p ><span v-if="item.opType == 1">{{ $t('manage.desc106') }}</span><span v-else>{{
                            $t('manage.desc107') }}</span></p>
                       
                    </div>
                    <div class="list-name flex">
                        <div>{{ $t('manage.desc100') }}</div>
                        <p>{{ $t('assetbag.desc85') }}</p>
                    </div>
                </div>
            </div>
            <div class="planet-page">
                <!-- :hide-on-single-page="true" -->
                <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="10" :current-page="page"
                    :total="totals" :hide-on-single-page="true" @current-change="handlerCurrent">
                </el-pagination>
            </div>
        </div>

        <van-popup v-model:show="startShow" position="bottom" :style="{ height: '40%' }">
            <van-datetime-picker v-model="startCurrentTime" :confirm-button-text="$t('home.desc20')"
                :cancel-button-text="$t('home.desc45')" type="date" @confirm="confirmStartFn()"
                @cancel="startShow = false" />
        </van-popup>
        <van-popup v-model:show="endShow" position="bottom" :style="{ height: '40%' }">
            <van-datetime-picker v-model="endCurrentTime" type="date" :confirm-button-text="$t('home.desc20')"
                :cancel-button-text="$t('home.desc45')" @confirm="confirmEndFn()" @cancel="endShow = false" />
        </van-popup>
    </div>
</template>

<script>
import h5header from "@/componets/h5header.vue";
export default {
    components: {
        h5header
    },
    data() {
        return {
            act: 1,
            value1: '',
            startTime: '',
            endTime: '',
            list: [],
            page: 1,
            showNull: false,
            loading: true,
            totals: 0,
            startShow: false,
            endShow: false,
            startCurrentTime: new Date(),
            endCurrentTime: new Date(),
            startTime1: '',
            endTime1: '',
            opType: ''
        }
    },
    mounted() {
        this.getlist()
    },
    methods: {
        handlerShowTime(i) {
            console.log(i)
        },
        newDate(time) {
            // 根据时间查询
            var date = new Date(time);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? "0" + m : m;
            var d = date.getDate();
            d = d < 10 ? "0" + d : d;
            return y + "-" + m + "-" + d;
        },
        confirmStartFn() {

            this.startTime1 = this.newDate(this.startCurrentTime)

            let time = this.startCurrentTime.getTime();
            this.startTime = time;
            this.startShow = false;
            if (this.endTime) {
                this.page = 1;
                this.list = [];
                this.showNull = false;
                this.getlist();
            }
        },
        confirmEndFn() {
            this.endTime1 = this.newDate(this.endCurrentTime)
            let time = this.endCurrentTime.getTime(), time1 = 24 * 60 * 60 * 1000 - 1;
            this.endTime = time + time1;
            this.endShow = false;
            if (this.startTime) {
                this.page = 1;
                this.list = [];
                this.getlist();
            }
        },
        handlerTab(i) {
            this.page = 1;
            this.act = i;
            this.list = [];
            this.opType = '';
            this.showNull = false;
            this.loading = true;


            this.getlist();
        },
        onChange(val) {
            this.page = 1;
            this.list = [];
            this.showNull = false;
            this.loading = true;
            this.getlist();
        },
        handlerCurrent(val) {
            this.showNull = false;
            this.page = val;
            this.list = [];
            this.getlist();
        },
        dateSelect(arr) {
            if (arr != null) {
                this.startTime = arr[0].getTime();
                // this.endTime = arr[1].getTime();
                let time = arr[1].getTime(), time1 = 24 * 60 * 60 * 1000 - 1;
                this.endTime = time + time1;
            } else {
                this.startTime = "";
                this.endTime = "";
            }
            this.showNull = false;
            this.page = 1;
            this.list = [];
            this.getlist()
        },
        getlist() {
            this.$post(this.URL.manage.record, {
                page: this.page,
                pageSize: 10,
                startTime: this.startTime,
                endTime: this.endTime,
                transType: this.act,
                opType: this.opType
            }).then(res => {
                this.loading = false;
                if (res.code == 0) {
                    this.list = res.data.records;
                    this.totals = parseFloat(res.data.total);
                    if (this.totals == 0) {
                        this.showNull = true
                    } else {
                        this.list.forEach(e => {
                            let time = new Date(e.createTime).getTime(), time2 = 4 * 60 * 60 * 1000;
                            e.createTime = this.common.formatDate(time + time2)
                        })

                    }
                } else {
                    this.$message.error(res.message);

                }
            })
        }

    }
}
</script>

<style lang="less" scoped>
.transfer {
    width: 100%;
    padding: 136px 0 80px;
    background: url("../../public/images/new/landbg.png") center no-repeat;
    background-size: 100% 100%;

    .transfer-con {
        width: 1240px;
        margin: 0 auto;

        .transfer-tbg {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.6);
            line-height: 14px;

            div {
                &:first-child {
                    cursor: pointer;
                }

                &:last-child {
                    color: #fff;

                    img {
                        width: 12px;
                        height: 14px;
                        margin: 0 8px;
                    }
                }
            }
        }

        .tabnav {
            margin: 32px 0 20px;
            font-size: 20px;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.6);

            div {
                cursor: pointer;

                &:hover {
                    color: #fff;
                }

                &:last-child {
                    margin-left: 20px;
                }
            }

            .tabact {
                color: #fff;
            }
        }

        .transfer-time {
            margin-bottom: 24px;

            ::v-deep .el-date-editor {
                flex: 0 0 400px;
                width: 400px;
                height: 40px;
                box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2) inset;
                background-color: rgba(255, 255, 255, 0);
                border: 1px solid rgba(255, 255, 255, 0.2) !important;

                .el-range-input {
                    color: #fff;
                }

                .el-range-separator {
                    color: #fff;
                }
            }

            ::v-deep .el-select {
                width: 300px;
                margin-left: 20px;

                .el-input__wrapper {
                    background-color: rgba(255, 255, 255, 0);
                    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2) inset;
                    height: 40px;

                    .el-input__inner {
                        color: #fff;
                    }
                }

                .is-focus {
                    box-shadow: 0 0 0 1px rgba(210, 251, 113, 1) inset !important;
                }
            }
        }

        .transfer-table {
            .table-thead {
                padding: 20px 24px;
                background: #181818;

                div {
                    flex: 1;
                    font-size: 13px;
                    color: rgba(255, 255, 255, 0.6);

                    &:nth-child(9),
                    &:nth-child(10) {
                        flex: 0 0 110px;
                    }

                    &:last-child {
                        flex: 0 0 80px;
                        text-align: right;
                    }
                }
            }

            .table-tbody {
                min-height: 100px;

                .tbody-list {
                    padding: 15px 24px;
                    border-bottom: 1px solid #1A1A1A;

                    div {
                        flex: 1;
                        font-size: 13px;
                        color: #fff;

                        &:nth-child(9),
                        &:nth-child(10) {
                            flex: 0 0 110px;
                        }

                        &:last-child {
                            flex: 0 0 80px;
                            text-align: right;
                        }
                    }
                }

                ::v-deep .el-loading-mask {
                    background-color: rgba(255, 255, 255, 0);

                    .el-loading-text {
                        color: rgba(255, 255, 255, 0.6);
                    }
                }
            }
        }

        .h5table-list,
        .rr-h5-time {
            display: none;
        }
    }
}

.no-data {
    width: 100%;
    padding: 130px 0;

    img {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto 16px;
    }

    span {
        display: block;
        margin: 0 auto;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #a1a1a1;
    }
}

.planet-page {
    margin-top: 40px;
    padding: 0;

    ::v-deep .el-pagination {
        button {
            background-color: rgba(255, 255, 255, 0);
            color: #666666;
            font-size: 14px;
        }

        .el-pager {
            li {
                background: rgba(255, 255, 255, 0);
                font-size: 14px;
                color: #666666;
            }

            .is-active {
                background: #c9fa5b !important;
                border-radius: 4px !important;
                color: #000000 !important;
            }
        }
    }
}

@media (max-width: 1200px) {
    .transfer {
        padding: 88px 0 60px;

        .transfer-con {
            width: 100%;
            padding: 0 16px;

            .transfer-table {
                display: none;
            }

            .h5table-list {
                display: block;
                min-height: 100px;

                ::v-deep .el-loading-mask {
                    background-color: rgba(255, 255, 255, 0);

                    .el-loading-text {
                        color: rgba(255, 255, 255, 0.6);
                    }
                }

                .list-con {
                    margin-bottom: 10px;
                    padding: 16px;
                    border-radius: 8px;
                    background: #181818;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .list-name {
                        margin-bottom: 16px;
                        font-size: 13px;
                        color: rgba(255, 255, 255, 0.6);

                        &:last-child {
                            margin-bottom: 0;
                        }

                        div {
                            flex: 0 0 40%;
                        }

                        p {
                            flex: 0 0 60%;
                            text-align: right;
                            color: #fff;
                        }
                    }
                }
            }
        }

    }

}

@media (max-width: 767px) {
    .transfer {
        padding: 56px 0;

        .transfer-con {
            .transfer-tbg {
                display: none;
            }

            .tabnav {
                margin: 0 0 16px 0;
                font-size: 16px;
            }

            .rr-h5-time {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 11px;
                height: 48px;
                padding: 0 10px;
                border: 1px solid rgba(255, 255, 255, 0.2);
                border-radius: 4px;

                .item {
                    flex: 1;
                    font-size: 13px;

                    div {

                        color: rgba(255, 255, 255, 0.3);
                    }

                    p {
                        color: #fff;
                    }
                }

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 18px;
                }

                span {
                    display: block;
                    font-family: "Bai Jamjuree";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                    text-transform: capitalize;
                    color: #ffffff;
                    padding: 0 18px;
                    width: 80px;
                    text-align: center;
                }

                ::v-deep .el-select {
                    width: 100%;


                    .el-input__wrapper {
                        background-color: rgba(255, 255, 255, 0) !important;
                        box-shadow:none !important;
                        height: 40px;
                        border: none;
                        padding: 0;
                        .el-input__inner {
                            color: #fff;
                        }
                    }

                    .is-focus {
                        box-shadow:none !important;
                    }
                }

            }

            .transfer-time {
                display: none;

            }
        }
    }
}
</style>